<template>
    <section>

 
   <div class="row">
     <div class="col-lg-12 grid-margin stretch-card">
       <div class="card">
         <div class="card-body">
           <div class="card-title">

                 <router-link to="/formations">
             <span class="float-right btn btn-info">
               retour
             </span>
             </router-link>
           </div>
           <div class="container">
             <form @submit.prevent="updateformation()" novalidate>
                <div class="row">
                 <div class="col-md-4">
                   <div class="form-group">
                     <label for="first"
                       >Theme <span class="obligatoire">*</span>
                     </label>

                     <input
                       v-model="theme"
                       type="text"
                       class="form-control"
                       placeholder=""
                       id="theme"
                       required
                     />
                   </div>
                 </div>

                 <div class="col-md-4">
                   <div class="form-group">
                     <label for="first"
                       >Adresse <span class="obligatoire">*</span>
                     </label>

                     <input
                       v-model="adresse"
                       type="text"
                       class="form-control"
                       placeholder=""
                       id="adresse"
                       required
                     />
                   </div>
                 </div>
                  
                 <!--  col-md-6   -->

                 <div class="col-md-4">
                   <div class="form-group">
                     <label for="last"
                       >Nb jour <span class="obligatoire">*</span></label
                     >
                     <input
                       v-model="nbjour"
                       type="number"
                       class="form-control"
                       placeholder=""
                       id="nbjour"
                      
                     />
                   </div>
                 </div>

                 <div class="col-md-4">
                   <div class="form-group">
                     <label for="last"
                       >Date debut
                       <span class="obligatoire">*</span></label
                     >
                     <input
                       v-model="date_debut"
                       type="date"
                       class="form-control"
                       placeholder=""
                       id="last"
                       required
                     />
                   </div>
                 </div>

                 <div class="col-md-4">
                   <div class="form-group">
                     <label for="last"
                       >Date fin
                       <span class="obligatoire">*</span></label
                     >
                     <input
                       v-model="date_debut"
                       type="date"
                       class="form-control"
                       placeholder=""
                       id="last"
                       required
                     />
                   </div>
                 </div>

                 <div class="col-md-4">
                   <div class="form-group">
                     <label for="last"
                       >Heure
                       <span class="obligatoire">*</span></label
                     >
                     <input
                       v-model="heure"
                       type="time"
                       class="form-control"
                       placeholder=""
                       id="last"
                       required
                     />
                   </div>
                 </div>


                 <div class="col-md-4">
                   <div class="form-group">
                     <label for="last"
                       >Durée
                       <span class="obligatoire">*</span></label
                     >
                     <input
                       v-model="duree"
                       type="number"
                       class="form-control"
                       placeholder=""
                       id="last"
                       required
                     />
                   </div>
                 </div>

                 <div class="col-md-4">
                  
                  <div class="form-group">
                    <label for="last"
                    >Emplacement <span class="obligatoire">*</span></label
                  >
                    <select
                      class="form-control"
                      id="exampleSelect"
                      v-model="emplacement"
                    >
                      <option value="En ligne">En ligne</option>
                      <option value="Présentielle">Présentielle</option>
                    </select>
                  </div>
              </div>

                 

                 <div class="col-md-4">
                  
                  <div class="form-group">
                    <label for="last"
                    >Membre <span class="obligatoire">*</span></label
                  >
                    <select
                      class="form-control"
                      id="exampleSelect"
                      v-model="membre"
                      @change="onSelectChange(membre)"
                    >
                      <option value="Par groupe">Par groupe</option>
                      <option value="Par personne">Par personne</option>
                    </select>
                  </div>
              </div>

              <div  class="col-md-4" id="delegue" style="display: none">
                    <div class="form-group">
                      <label for="last"
                        >Délégué <span class="obligatoire">*</span></label
                      >
                      <select
                        id="inputState"
                        class="form-control"
                        v-model="user_id"
                        required
                      >
                        <option
                          v-for="user in users"
                          :key="user._id"
                          v-bind:value="user._id"
                        >
                          {{ user.name }}
                        </option>
                      </select>
                    </div>
                  </div> 


                  <div class="col-md-4" id="groupe" style="display: none">
                    <div class="form-group">
                      <label for="last"
                        >Groupe <span class="obligatoire">*</span></label
                      >
                      <select
                        id="inputState"
                        class="form-control"
                        v-model="group_id"
                        required
                      >
                        <option
                          v-for="groupe in groups"
                          :key="groupe._id"
                          v-bind:value="groupe._id"
                        >
                          {{ groupe.name }}
                        </option>
                      </select>
                    </div>
                  </div> 

                 <!--  col-md-6   -->
                 
                    <div class="col-md-4">
                        <div class="form-group">
            <label for="last"
                        >Plan<span class="obligatoire"
                          >*</span
                        ></label>
            <div class="input-group col-md-14">
              <input
                type="file"
                class="form-control file-upload-info"
                placeholder="Upload Image"
                @change="uploadFile"
                ref="file"
              /> 
                  
              <span class="input-group-append">
                <button
                  class="file-upload-browse btn btn-gradient-primary"
                  type="button"
                >
                  Télécharger
                </button>
              </span>
            </div>
          </div> 
                 </div>

                
                 <!--  col-md-6   -->

                 <!--  col-md-6   -->
               </div>

             <button type="submit" class="btn btn-primary" >Modifier Formation</button>
             </form>
           </div>
         </div>
       </div>
     </div>
   </div>
 </section>
</template>
<script>
import { HTTP } from '@/axios';
import moment from 'moment';
export default {
    data() {
   return {
     file:"",
     plan: "",
     theme: "",
     adresse: "",
     nbjour: "",
     date_debut:"",
     date_fin:"",
     emplacement:"",
     membre:"",
     duree:"",
     heure:"",

 
     
   }
 },
  created: function () {
    this.getFormationById();
    this.getusers();
    this.getgroups();

 },
   methods: {
      uploadFile () {
       this.file = this.$refs.file.files[0];
       console.log(this.file);
       
       },
       getusers() {
      HTTP.get("users/getusers")
        .then((response) => {
          console.log(response.data);
          this.users = response.data;
        })
        .then((err) => console.log(err));
    },
    getgroups() {
      HTTP.get("groupes/getgroups")
        .then((response) => {
          console.log(response.data);
          this.groups = response.data;
        })
        .then((err) => console.log(err));
    },
       getFormationById(){
           
           HTTP.get('formations/getformationbyid/'+this.$route.params.id)
        .then(res => {
        
        
         this.nbjour=res.data.nbjour;
         this.theme=res.data.theme;
         this.adresse=res.data.adresse;
         this.emplacement=res.data.emplacement;
         this.membre=res.data.membre;
         this.heure=res.data.heure;
         this.duree=res.data.duree;

         this.date_fin = moment(res.data.date_fin).format(
           "YYYY-MM-DD"
         );

         this.date_debut = moment(res.data.date_debut).format(
           "YYYY-MM-DD"
         );



       }, err => {
          this.error = err.response.data.error
       })
       },
   updateformation() {
     const formData = new FormData();
       formData.append('plan', this.file)
       formData.append('_id', this.$route.params.id)
       formData.append('theme', this.theme)
       formData.append('nbjour', this.nbjour)
       formData.append('adresse', this.adresse)
       formData.append('emplacement', this.emplacement)
       formData.append('membre', this.membre)
       formData.append('date_debut', this.date_debut)
       formData.append('date_fin', this.date_fin)
       formData.append('user_id', this.user_id)
      formData.append('group_id', this.group_id)
      formData.append('heure', this.heure)
      formData.append('duree', this.duree)

     // let produit = {
     // prix: this.prix,
     // reference: this.reference,
     // description: this.description,
     // quantites: this.quantites,
     //  quantites_minimal:this.quantites_minimal,
     // categorie:this.categorie,
     // fournisseur:this.fournisseur,
     // designation:this.designation,
     // prix:this.prix,
     // date_debut:this.date_debut,
     // date_fin:this.date_fin,
     // _id:this.$route.params.id

     // };
      HTTP.put("formations/modifierformation/", formData)
        .then(res => {
        
          if (res.status === 200) {
                   Toast.fire({
                       icon: "success",
                       title: "formation modifié"
          });
           this.$router.push('/formations');
         
          }
       }, err => {
          this.error = err.response.data.error
          console.log(this.error);
       })
   },

   onSelectChange(data) {
      if (data === "Par groupe") {
        document.getElementById("groupe").style.display = "block";
        document.getElementById("delegue").style.display = "none";
      } else if (data === "Par personne") {
        document.getElementById("groupe").style.display = "none";
        document.getElementById("delegue").style.display = "block";
      }
    },

 }
 
}
</script>